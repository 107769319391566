/*
 * Copyright © 2022. wrappr.
 */
import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ImprintPageDE() {
  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h1"} textAlign={"center"}>
            Impressum
          </Typography>
          <p>
            <b>Angaben gemäß § 5 TMG und verantwortlich gemäß § 55 Abs. 2 RStV</b><br />
            Andreas Quauke<br />
            c/o R&S Internet Jewels GmbH<br />
            Anna-Schneider-Steig 5<br />
            50678 Köln<br />
            <br />
            <b>Kontakt</b><br />
            Telefon: 0221 98651940<br />
            Email: hello@wrappr.xyz<br />
            <br />
            <b>Hinweis auf EU-Streitschlichtung</b>
            <br />
            Die europäische Webseite für Online-Schlichtungen ist:
            <a href="https://ec.europa.eu/consumers/odr/"> https://ec.europa.eu/consumers/odr/</a><br />
            Wir sind zur Teilnahme an der Streitschlichtung weder verpflichtet, noch nehmen wir hieran freiwillig teil.<br />
            <br />
            <b>Datenschutz</b><br />
            Die Datenschutzerklärung von wrappr finden Sie <Link to={"/datenschutz"}> hier</Link>.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
}
