import _ from "lodash";
import { API_HOST, API_PATH } from "../config/globalVars";
import { loadToken } from "./localStorage";
import ExtendableError from "es6-error";

const apiEndpoint = API_HOST + API_PATH;
const defaultAcceptHeader = "application/ld+json";
const defaultContentTypeHeader = "application/ld+json";

export class SubmissionError extends ExtendableError {
  private errors: any;
  private status: any;

  constructor(errors: any, status: any) {
    super(errors["_error"]);
    this.errors = errors;
    this.status = status;
  }
}

class ResponseError extends ExtendableError {
  private error: string | undefined;
  private status: any;

  constructor(error: string | undefined, status: any) {
    super(error);
    this.error = error;
    this.status = status;
  }
}

class ApiClient {
  async getToken(credentials: Object) {
    const json = this.post("login_check", { body: JSON.stringify(credentials) });
    const token = _.get(await json, "token");
    if (!token || !_.isString(token)) {
      throw new Error(
        "Für diese Kombination aus E-Mail-Adresse und Passwort konnten keine Daten gefunden werden." +
          " Bitte überprüfen Sie Ihre eingegebenen Daten."
      );
    }
    return token;
  }

  async get(iri: any, options = {}) {
    return this.call("GET", iri, options);
  }

  async post(iri: any, options = {}) {
    return this.call("POST", iri, options);
  }

  async put(iri: any, options = {}) {
    return this.call("PUT", iri, options);
  }

  async delete(iri: any, options = {}) {
    return this.call("DELETE", iri, options);
  }

  async call(method: string, iri: any, options: any) {
    const url = apiEndpoint + _.trimStart(iri, "/");

    options.method = method;
    this.defaultOptions(options);

    const response = await fetch(url, options);
    if (response.ok && response.status === 204) {
      return null;
    }

    const json = await response.json();
    if (!response.ok) {
      return this.handleError(json, response);
    }
    return json;
  }

  defaultOptions = (options: any) => {
    if ("undefined" === typeof options.headers) {
      options.headers = new Headers();
    }

    if (null === options.headers.get("Accept")) {
      options.headers.set("Accept", defaultAcceptHeader);
    }

    if (
      "undefined" !== options.body &&
      !(options.body instanceof FormData) &&
      null === options.headers.get("Content-Type")
    ) {
      options.headers.set("Content-Type", defaultContentTypeHeader);
    }

    if (options.headers.get("Content-Type") === "multipart/form-data") {
      options.headers.delete("Content-Type");
    }

    const token = loadToken();
    if (null !== token && null === options.headers.get("Authorization")) {
      options.headers.set("Authorization", "Bearer " + token);
    }
  };

  handleError(json: { [x: string]: any; violations: any }, response: Response) {
    const error = json["hydra:description"] || response.statusText;
    if (!json.violations) {
      return new ResponseError(error, response.status);
    }
    let errors: any = {};
    _.map(json.violations, (violation) => (errors[violation.propertyPath] = violation.message));
    return new SubmissionError(errors, response.status);
  }
}

export default new ApiClient();
