/*
 * Copyright © 2022. Stampr.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./translations/en";
import { de } from "./translations/de";

const resources = {
  en: en,
  de: de
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
