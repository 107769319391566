import React, { ForwardedRef } from "react";
import { styles } from "./caseRefreshNotificationStyle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { SnackbarKey, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface OrderRefreshNotificationProps {
  id: SnackbarKey,
  orderUpdate: {
    status: string
  }
}

const OrderUpdateNotification = React.forwardRef((props: OrderRefreshNotificationProps, ref:ForwardedRef<any>) => {
  const { orderUpdate, id } = props;
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const closeNotification = () => {
    closeSnackbar(id);
  };
  return (
    <Paper sx={styles.notificationContainer} ref={ref}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2} />
        {orderUpdate &&
          <Grid item xs={8} sx={styles.headline}>
            {t("status." + orderUpdate.status)}
          </Grid>
        }
        <Grid item xs={2}>
          <IconButton onClick={closeNotification} size="large">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
});

export default OrderUpdateNotification;
