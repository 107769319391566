/*
 * Copyright © 2022. wrappr.
 */
import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ImprintPageEN() {
  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h1"} textAlign={"center"}>
            Imprint
          </Typography>
          <p>
            <b>Information according to § 5 TMG and responsible according to § 55 Abs. 2 RStV</b><br />
            Andreas Quauke<br />
            c/o R&S Internet Jewels GmbH<br />
            Anna-Schneider-Steig 5<br />
            50678 Cologne<br />
            <br />
            <b>Kontakt</b><br />
            Phone: 0221 98651940<br />
            Email: hello@wrappr.xyz<br />
            <br />
            <b>Reference to EU dispute settlement</b>
            <br />
            The European website for online dispute settlement is:
            <a href="https://ec.europa.eu/consumers/odr/"> https://ec.europa.eu/consumers/odr/</a><br />
            We are neither obliged to participate in dispute settlement nor do we participate voluntarily.<br />
            <br />
            <b>Datenschutz</b><br />
            wrappr's privacy policy can be found <Link to={"/privacy"}> here</Link>.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
}
