/*
 * Copyright © 2022. wrappr.
 */

import ApiClient from "../../services/ApiClient";
import * as actionTypes from "./actionTypes"
import _isArrayLike from "lodash/isArrayLike";
import _isObject from "lodash/isObject";
import _head from "lodash/head";
import { clearCurrentUser, persistCurrentUser, persistToken } from "../../services/localStorage";

interface Credentials {
  email: string;
  password: string;
}

export function getToken(credentials: Credentials) {
  return async (dispatch: (arg0: { type: any; token: string; }) => void) => {
    const token = await ApiClient.getToken(credentials);
    dispatch({ type: actionTypes.TOKEN_RECEIVED, token: token });
    return token;
  };
}

export async function register(dispatch: any, registerPayload: { email: string; }) {
  clearCurrentUser();
  try {
    dispatch({ type: actionTypes.REQUEST_LOGIN })
    const appUser = await ApiClient.post("/app_users", { body: JSON.stringify(registerPayload) });
    if (appUser.token) {
      persistCurrentUser(appUser);
      dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: appUser })
    }
    return appUser;
  } catch (e) {
    console.log(e)
  }
}


export function login(credentials: Credentials) {
  return async (dispatch: any) => {
    const token = await ApiClient.getToken(credentials);
    console.log(token);
    persistToken(token);

    const appUser = await ApiClient.get("app_users/me");
    dispatch({ type: actionTypes.REQUEST_LOGIN, appUser: appUser["hydra:member"][0] });
  };
}


export function logout() {
  return async (dispatch: (arg0: { type: string; }) => void) => {
    dispatch({ type: actionTypes.LOGOUT });
  };
}

export function sendRecoveryPasswordEmail(email: string) {
  return async () => {
    const customersCollection = await ApiClient.get(
      "/customers/password_reset?email=" + encodeURIComponent(email.toLowerCase())
    );
    if (!_isArrayLike(customersCollection)) {
      throw new Error("Ihre Kundendaten konnten nicht gefunden werden.");
    }

    const customer = _head(customersCollection);
    if (!_isObject(customer)) {
      throw new Error("Ihre Kundendaten konnten nicht gefunden werden.");
    }

    // @ts-ignore
    return customer.email;
  };
}

export function resetPassword(id: number, body: Object) {
  return async () => {
    return await ApiClient.put(`/customers/${id}/set_password`, { body: JSON.stringify(body) });
  };
}

export function checkEmail(email: string) {
  return async () => {
    try {
      let response = await ApiClient.post("/check_email", { body: JSON.stringify({ email: email.toLowerCase() }) });
      response.hasError = response.customerExists;
      response.errorText = null;
      return response;
    } catch (e) {
      return {
        hasError: true,
        errorText: "Ihre E-Mail-Adresse ist ungültig",
      };
    }
  };
}


export function checkRecovery(id: number, token: string) {
  return async () => {
    return await ApiClient.get("/check_recovery?t=" + token + "&id=" + id);
  };
}
