/*
 * Copyright © 2022. Stampr.
 */

import _isEmpty from "lodash/isEmpty";

export const isRequired = (value: any) => {
  return !_isEmpty(value);
};

