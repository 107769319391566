export const styles = {
  icon: {
    display: {
      xs: "flex",
    },
    mr: 1,
  },
  navIcon: {
    ml: "auto",
  },
  userIcon: {
    ml: "auto",
  },
};
