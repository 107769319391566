import * as React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import boredApeImage from "../../assets/bored_ape.png";
import generativeArtImage from "../../assets/generative_art.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function ChoosePicturePage() {
  const { t } = useTranslation();
  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Generate your own Artwork or upload a picture")}
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Box component="img" src={generativeArtImage} sx={{ maxWidth: "100%", margin: "auto" }} />
        </Grid>
        <Grid item>
          <Button variant={"contained"} size={"large"} component={Link} to={"/generate-art"}>
            {t("Generate artwork")}
          </Button>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Box component="img" src={boredApeImage} sx={{ maxWidth: "100%", margin: "auto" }} />
        </Grid>
        <Grid item>
          <Button variant={"outlined"} size={"large"} component={Link} to={"/upload-picture"}>
            {t("Upload picture")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
