import React from "react";
import { useForm } from "../../../context/Form/FormProvider";
import { Grid } from "@mui/material";
import { RadioCardGroup } from "../../FormComponents/RadioCardGroup/RadioCardGroup";
import { EthereumIcon } from "../../SvgIcon/EthereumIcon";
import { FormValueTypes } from "../../../types/FormValueTypes";
import ValidatorTextField from "../../FormComponents/ValidatorTextField/ValidatorTextField";
import FormSubmit from "../../FormComponents/FormSubmit/FormSubmit";
import { VoucherCodeGenerator } from "../../VoucherCodeGenerator/VoucherCodeGenerator";
import { useTranslation } from "react-i18next";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";

const CryptoAddressVoucherForm = () => {
  const { handleSubmit, values, handleChange } = useForm();
  const { nftCreationProcedure }: FormValueTypes = values;
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioCardGroup
            name={"nftCreationProcedure"}
            question={t("nftCreationProcedure.question")}
            options={[
              {
                title: t("nftCreationProcedure.options.sendToWallet.title"),
                text: t("nftCreationProcedure.options.sendToWallet.text"),
                icon: <AccountBalanceWalletOutlined/>,
                value: "sendToWallet",
              },
              {
                title: t("nftCreationProcedure.options.createVoucher.title"),
                text: t("nftCreationProcedure.options.createVoucher.text"),
                icon: <EthereumIcon />,
                value: "createVoucher",
              },
            ]}
            initialSelectedValue={"createVoucher"}
          />
        </Grid>
        {nftCreationProcedure === "createVoucher" && (
          <Grid item xs={12}>
            <VoucherCodeGenerator handleChange={handleChange} />
          </Grid>
        )}
        {nftCreationProcedure === "sendToWallet" && (
          <Grid item xs={12}>
            <ValidatorTextField
              id={"targetCryptoAddress"}
              name={"targetCryptoAddress"}
              question={"What crypto address would you like to send it to?"}
              label={"Crypto address or name"}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormSubmit />
        </Grid>
      </Grid>
    </form>
  );
};

export default CryptoAddressVoucherForm;
