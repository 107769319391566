import { AbiCoder, defaultAbiCoder } from "@ethersproject/abi";
import { keccak256 } from "@ethersproject/keccak256";

/*
 *
 *  helper functions
 *
 */

// Helper function to prevent ambiguous failure message when dates aren't passed

export async function getBlockNumber(w3provider) {
  return w3provider.getBlockNumber();
}

export function encodeParameters(types, values) {
  let abi = new AbiCoder();
  return abi.encode(types, values);
}

export function decodeParameters(types, values) {
  let abi = new AbiCoder();
  return abi.decode(types, values);
}

export function hashKeccak256(rawString) {
  let abiEncodedString = defaultAbiCoder.encode(["string"], [rawString]);
  return keccak256(abiEncodedString);
}
