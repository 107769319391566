/*
 * Copyright © 2022. wrappr.
 */

const STATUS_CREATED = 10;
const STATUS_PAYMENT_INITIALIZED = 20;
const STATUS_PAYMENT_RUNNING_CHECKS = 30;
const STATUS_PAYMENT_PROCESSING = 40;
const STATUS_PAYMENT_COMPLETED = 50;
const STATUS_PINNING = 60;
const STATUS_PINNED = 70;
const STATUS_NFT_MINTING = 80;
const STATUS_NFT_MINTED = 90;
const STATUS_NFT_CHARGING = 100;
const STATUS_NFT_CHARGED = 110;
const STATUS_VOUCHER_HASH_ADDING = 120;
const STATUS_VOUCHER_HASH_ADDED = 130;
const STATUS_REDEEMING_CODE = 140;
const STATUS_CODE_REDEEMED = 150;

const ORDER_STATUS_SET = [
  STATUS_CREATED,
  STATUS_PAYMENT_INITIALIZED,
  STATUS_PAYMENT_RUNNING_CHECKS,
  STATUS_PAYMENT_PROCESSING,
  STATUS_PAYMENT_COMPLETED,
  STATUS_PINNING,
  STATUS_PINNED,
  STATUS_NFT_MINTING,
  STATUS_NFT_MINTED,
  STATUS_NFT_CHARGING,
  STATUS_NFT_CHARGED,
  STATUS_VOUCHER_HASH_ADDING,
  STATUS_VOUCHER_HASH_ADDED,
  STATUS_REDEEMING_CODE,
  STATUS_CODE_REDEEMED
];

export {
  STATUS_CREATED,
  STATUS_PAYMENT_INITIALIZED,
  STATUS_PAYMENT_RUNNING_CHECKS,
  STATUS_PAYMENT_PROCESSING,
  STATUS_PAYMENT_COMPLETED,
  STATUS_PINNING,
  STATUS_PINNED,
  STATUS_NFT_MINTING,
  STATUS_NFT_MINTED,
  STATUS_NFT_CHARGING,
  STATUS_NFT_CHARGED,
  STATUS_VOUCHER_HASH_ADDING,
  STATUS_VOUCHER_HASH_ADDED,
  STATUS_REDEEMING_CODE,
  STATUS_CODE_REDEEMED,
  ORDER_STATUS_SET
};
