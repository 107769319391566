/*
 * Copyright © 2022. wrappr.
 */
import React from "react";
import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export default function FooterLinkItem({ text, to }: { text: string, to: string }) {
  const theme = useTheme();
  return (
    <Box component={Link} to={to} sx={{ color: theme.palette.grey[700], textDecoration: "none" }}>
      {text}
    </Box>
  );
}
