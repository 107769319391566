import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import App from "./App";
import DarkModeProvider from "./context/DarkMode/DarkModeProvider";
import Web3Provider from "./context/Web3/Web3Provider";
import LanguageProvider from "./context/Language/LanguageProvider";
import { accessGranted } from "./services/environment";
import { AuthProvider } from "./context/Auth";


function startApp() {
  const container = document.getElementById("app");
// @ts-ignore
  let root = createRoot(container);

  if (!accessGranted(window.location.search)) {
    root.render(
      <div>
        To the moon...
      </div>
    );
    return;
  }

  root.render(
    <StrictMode>
      <Web3Provider>
        <DarkModeProvider>
          <LanguageProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </LanguageProvider>
        </DarkModeProvider>
      </Web3Provider>
    </StrictMode>
  );
}

startApp();
