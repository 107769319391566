/*
 * Copyright © 2022. wrappr.
 */

import * as React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { FormProvider } from "../../context/Form/FormProvider";
import { FormValueTypes } from "../../types/FormValueTypes";
import { UploadForm } from "../../components/Forms/UploadForm/UploadForm";
import _isEmpty from "lodash/isEmpty";
import ApiClient from "../../services/ApiClient";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function UploadPicturePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let initialValues: FormValueTypes = {
    nftCreationProcedure: "createVoucher",
    amount: "",
    ethToUSDConversion: 1300.00,
    fileUploadData: null,
    nftTitle: "",
    creditCardNumber: "",
    cvc: "",
    creditCardExpiryDate: "",
    givenName: "",
    familyName: "",
    cardNumber: "",
    generatedVoucherCode: "",
    voucherCodeHash: "",
    quote: null
  };

  const handleSubmit = async ({ values, errors }: any) => {
    let quote = await ApiClient.post("wyre/get_quote", {
      body: JSON.stringify({
          "destCurrency": "ETH",
          "destAmount": 1.0,
        }),
    });
    values.quote = quote;
    values.ethToUSDConversion = quote.equivalencies.USD;
    localStorage.setItem("values", JSON.stringify(values));
    if (_isEmpty(errors)) {
      navigate("/value-supply");
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Upload your Picture")}
          </Typography>
          <Typography variant={"h2"} textAlign={"center"}>
            {t("Click here to choose your picture")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
            <UploadForm />
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
