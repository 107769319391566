import OrderUpdateNotification from "../Notifications/OrderUpdateNotification";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";
import { WrapprOrderTypes } from "../../types/WrapprOrderTypes";

interface OrderNotificationProps {
  order: WrapprOrderTypes|null,
  setHasUpdates: Function
}

const OrderNotifications = ({ order, setHasUpdates }: OrderNotificationProps) => {
  const [notification, setNotification] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const cookieFetched = isCookieFetched();
  useEffect(() => {
    if (cookieFetched && order) {
      const eventSource = mercureSubscribe("/wrappr_orders/" +  order.id, setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched, order]);

  useEffect(() => {
    setHasUpdates(true);
    showOrderUpdate(notification);
  }, [notification]);

  const showOrderUpdate = (orderUpdate: null) => {
    if (!orderUpdate) {
      return;
    }
    setHasUpdates(true);
    enqueueSnackbar("", {
      content: (key) => <OrderUpdateNotification id={key} orderUpdate={orderUpdate} />,
    });
  };

  return null;
};
export default OrderNotifications;
