import * as React from "react";
import { SelectionCard } from "../../SelectionCard/SelectionCard";
import { useForm } from "../../../context/Form/FormProvider";
import _map from "lodash/map";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { EthereumIcon } from "../../SvgIcon/EthereumIcon";
import { FormLabel, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RadioCardGroupProps {
  name: string;
  question: string | null;
  options: Array<any>;
  initialSelectedValue?: string;
}

export function RadioCardGroup({ name, question, options, initialSelectedValue }: RadioCardGroupProps) {
  const { handleChange } = useForm();
  const [value, setValue] = React.useState(initialSelectedValue);
  const { t } = useTranslation();

  const handleChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    handleChange(event);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      {question && (
        <FormLabel id={name + "-label"} sx={{ mb: 4 }}>
          <Typography variant={"h2"} textAlign={"center"}>
            {question}
          </Typography>
        </FormLabel>
      )}
      <RadioGroup name={name} value={value} onChange={handleChangeInternal}>
        <Grid container spacing={2}>
          {_map(options, (option, index) => {
            return (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  disableTypography
                  sx={{ margin: 0 }}
                  value={t(option.value)}
                  control={<Radio sx={{ display: "none" }} />}
                  label={
                    <SelectionCard
                      active={value === option.value}
                      icon={<EthereumIcon />}
                      title={option.title}
                      text={option.text}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
