/*
 * Copyright © 2022. wrappr.
 */

import { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import LanguageContext from "./LanguageContext";
import i18n from "../../i18n";

const LanguageProvider = (props: {
  children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  const [language, setLanguage] = useState('en');

  async function toggleLanguage(language: string) {
    await i18n.changeLanguage(language);
    setLanguage(language);
  }

  return <LanguageContext.Provider value={{ language, toggleLanguage }}>{props.children}</LanguageContext.Provider>;
};

export default LanguageProvider;
