import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { useContext, useState } from "react";
import DarkModeContext from "../../context/DarkMode/DarkModeContext";
import Web3Context from "../../context/Web3/Web3Context";
import logo from "../../assets/wrappr_logo.png";
import styled from "@emotion/styled";
import ConnectionIndicator from "./ConnectionIndicator";
import { styles } from "./responsiveAppBarStyles";
import { NavMenu } from "../NavMenu/NavMenu";
import LanguageContext from "../../context/Language/LanguageContext";
import { Link } from "react-router-dom";


const Logo = styled("img")({
  maxHeight: 30
});

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  const { language, toggleLanguage } = useContext(LanguageContext);
  const { loadWeb3Modal, logoutOfWeb3Modal, walletConnected } = useContext(Web3Context);


  const handleOpenNavMenu = (event: { currentTarget: React.SetStateAction<HTMLElement | null> }) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" color={"primary"}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={styles.icon} component={Link} to={"/"}>
            <Logo src={logo} alt={"wrappr"} />
          </Box>

          <Typography component={"span"} variant={"h6"}>
            wrappr
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={styles.userIcon}>
            <Tooltip title="Open settings">
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                <ConnectionIndicator active={walletConnected} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={styles.navIcon}>
            <IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <NavMenu
              anchorElNav={anchorElNav}
              handleCloseNavMenu={handleCloseNavMenu}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
              walletConnected={walletConnected}
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
              loadWeb3Modal={loadWeb3Modal}
              switchLanguage={toggleLanguage}
              language={language}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
