export const styles = {
  notificationContainer: {
    padding: "1rem",
    border: "1px solid #f28d4f",
    borderRadius: 5,
    maxWidth: 400,
    fontSize: "1rem",
  },
  headline: {
    fontSize: "1.25rem",
    textAlign: "center",
  }
};
