/*
 * Copyright © 2022. Stampr.
 */

export const en = {
  translation: {
    "imprint": "Imprint",
    "estimatedOrderValuePhrase": "wrappr charged with {{amount}} ETH ~ ${{estimatedPurchasePriceUSD}} estimated order value",
    "summaryBoxValuePhrase": "wrappr charged with {{amount}} ETH value",
    "summaryBoxEstimatedOrderValue": "~ ${{totalValue}} estimated order value",
    "paymentButtonText": "Pay ${{totalValue}}",
    status: {
      10: "Order created",
      20: "Payment initialized",
      30: "Payment checks running",
      40: "Payment authorized & blockchain transaction started",
      50: "Blockchain transaction completed",
      60: "Uploading wrappr image",
      70: "wrappr image uploaded successfully",
      80: "Minting wrappr NFT",
      90: "wrappr NFT minted successfully",
      100: "Charging wrappr NFT",
      110: "wrappr NFT charged successfully",
      120: "Adding voucher code",
      130: "Voucher code ready to redeem",
      140: "Redeeming voucher code",
      150: "Voucher code redeemed successfully",
    },
    nftCreationProcedure: {
      question: "Send to wallet or generate voucher code?",
      options: {
        sendToWallet: {
          title: "Send to a wallet",
          text: "I know the crypto address of the receiver I want to send the stamps to.",
        },
        createVoucher: {
          title: "Generate voucher code",
          text: "I do not know the address of the receiver or the receiver does not have a wallet yet. I want to generate a code instead that can be redeemed later.",
        }
      }
    },
    email: {
      label: "Email Address",
      helperText: "wrappr only sends an order confirmation to this email address. We do not create an account for you " +
        "and you can register later if wanted.",
    },
    cardNumber: {
      label: "Card number",

    },
    givenName: {
      label: "First name"
    },
    familyName: {
      label: "Surname"
    },
    creditCardExpiryDate: {
      label: "Expires at"
    },
    cvc: {
      label: "CVC Code"
    },
    voucherRedeemTargetAddress: {
      label: "Crypto address or .eth domain",
      question: "What crypto address would you like to send it to?"
    },
    voucherCodeRaw: {
      label: "Voucher code",
      question: "Please type in your voucher code"
    }
  }
};
