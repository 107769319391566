import PropTypes from "prop-types";
import React, { FocusEventHandler, ReactNode, useEffect } from "react";
import { FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { formValue } from "../../../services/formServiceFunctions";
import _get from "lodash/get";
import { useForm } from "../../../context/Form/FormProvider";
import MaskedInput from "react-text-mask";
import { useTranslation } from "react-i18next";

interface ValidatorTextFieldTypes {
  id: string;
  name: string;
  mask: Array<RegExp | string>;
  label: string;
  question?: string;
  startAdornment?: ReactNode;
  onFocus?: FocusEventHandler;
  helperText?: string;
  validators?: Array<any>;
  dependentValidationFields?: Array<any>;
}

export default function MaskedValidatorTextField({
                                                   id,
                                                   name,
                                                   mask,
                                                   label,
                                                   question,
                                                   onFocus,
                                                   helperText,
                                                   validators,
                                                   dependentValidationFields
                                                 }: ValidatorTextFieldTypes) {
  const { values, errors, setError, handleChange, handleBlur, registerValidators } = useForm();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, []);
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: "100%" }}>
      {question && (
        <FormLabel id={id + "-label"} sx={{ mb: 4 }}>
          <Typography variant={"h2"} textAlign={"center"}>
            {t(question)}
          </Typography>
        </FormLabel>
      )}
      <MaskedInput
        style={{ marginTop: 16, marginBottom: 8 }}
        mask={mask}
        guide={false}
        id={id}
        name={name}
        value={formValue(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        render={(ref, props) => {
          return (
            <TextField
              label={t(label)}
              error={error}
              helperText={t(_get(errors, name) || helperText)}
              inputRef={ref}
              {...props}
            />
          );
        }}
      />
    </FormControl>
  );
}
MaskedValidatorTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validators: PropTypes.array.isRequired,
  dependentValidationFields: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(["normal", "dense"])
};
MaskedValidatorTextField.defaultProps = {
  fullWidth: true,
  margin: "normal",
  validators: [],
  dependentValidationFields: []
};
