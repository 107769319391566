import * as React from "react";
import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { darkTheme, lightTheme } from "./theme/Theme.js";
import DarkModeContext from "./context/DarkMode/DarkModeContext";
import { Layout } from "./components/Layout/Layout";
import TestPage from "./pages/TestPage/TestPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { ChoosePicturePage } from "./pages/ChoosePicturePage/ChoosePicturePage";
import { UploadPicturePage } from "./pages/UploadPicturePage/UploadPicturePage";
import { ValueChargePage } from "./pages/ValueChargePage/ValueChargePage";
import { SummaryPage } from "./pages/SummaryPage/SummaryPage";
import { PaymentPage } from "./pages/PaymentPage/PaymentPage";
import { ThankYouPage } from "./pages/ThankYouPage/ThankYouPage";
import { RedeemPage } from "./pages/RedeemPage/RedeemPage";
import { ImprintPage } from "./pages/ImprintPage/ImprintPage";
import ScrollToTop from "./services/ScrollToTop";
import { MyWrapprsPage } from "./pages/MyWrapprsPage/MyWrapprsPage";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline enableColorScheme>
          <BrowserRouter>
            <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={1}>
              <ScrollToTop>
                <Layout>
                  <Routes>
                    <Route path={"/choose-picture"} element={<ChoosePicturePage />} />
                    <Route path={"/upload-picture"} element={<UploadPicturePage />} />
                    <Route path={"/value-supply"} element={<ValueChargePage />} />
                    <Route path={"/summary"} element={<SummaryPage />} />
                    <Route path={"/payment"} element={<PaymentPage />} />
                    <Route path={"/thank-you"} element={<ThankYouPage />} />
                    <Route path={"/redeem"} element={<RedeemPage />} />
                    <Route path={"/my-wrapprs"} element={<MyWrapprsPage />} />
                    <Route path={"/test"} element={<TestPage />} />
                    <Route path={"/imprint"} element={<ImprintPage />} />
                    <Route path={"/"} element={<HomePage />} />
                  </Routes>
                </Layout>
              </ScrollToTop>
            </SnackbarProvider>
          </BrowserRouter>
        </CssBaseline>
      </ThemeProvider>
    </>
  );
}

export default App;
