/*
 * Copyright © 2022. wrappr.
 */

import * as React from "react";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { hashKeccak256 } from "../../services/contract-functions";


type VoucherCodeGeneratorProps = {
  handleChange?: ChangeEventHandler;
};

export function VoucherCodeGenerator(props: VoucherCodeGeneratorProps) {
  const { handleChange } = props;
  const [voucherCode, setVoucherCode] = useState<string>("<...>");

  function generateVoucherCode(length: number = 10) {
    let generatedVoucherCode = "";

    const validChars = "0123456789" + "abcdefghijklmnopqrstuvwxyz" + "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "-{}+!#$%/[]()=?";

    for (let i = 0; i < length; i++) {
      let randomNumber = crypto.getRandomValues(new Uint32Array(1))[0];
      randomNumber = randomNumber / 0x100000000;
      randomNumber = Math.floor(randomNumber * validChars.length);

      generatedVoucherCode += validChars[randomNumber];
    }

    if (handleChange) {
      let voucherCodeHash = hashKeccak256(generatedVoucherCode);
      let event1: ChangeEvent = {
        target: {
          // @ts-ignore
          name: "voucherCodeHash",
          value: voucherCodeHash
        }
      };
      let event2: ChangeEvent = {
        target: {
          // @ts-ignore
          name: "generatedVoucherCode",
          value: generatedVoucherCode
        }
      };
      handleChange(event1);
      handleChange(event2);
    }

    setVoucherCode(generatedVoucherCode);
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant={"h2"} textAlign={"center"}>
          Generate your voucher code
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h1"} textAlign={"center"} color={"primary"} sx={{ fontFamily: "courier" }}>
          {voucherCode}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button
          onClick={() => generateVoucherCode()}>{voucherCode == "<...>" ? "Generate voucher code" : "Renew code"}
        </Button>
      </Grid>
    </Grid>
  );
}
