/*
 * Copyright © 2022. wrappr.
 */

import { ReactChild, ReactFragment, ReactPortal, useState } from "react";
import DarkModeContext from "./DarkModeContext";

const DarkModeProvider = (props: {
  children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  const [darkMode, setDarkMode] = useState(true);

  function toggleDarkMode() {
    setDarkMode((darkMode) => !darkMode);
  }

  return <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>{props.children}</DarkModeContext.Provider>;
};

export default DarkModeProvider;
