/*
 * Copyright © 2022. Stampr.
 */

export const de = {
  translation: {
    "My wrapprs": "Meine wrappers",
    "To see your wrapprs, please connect your wallet": "Um Deine wrappr anzusehen, verbinde Dich bitte mit Deinem Wallet",
    "Imprint": "Impressum",
    "imprintLink": "/de/imprint",
    "Give a wrappr": "Schenke einen wrappr",
    "Personal. Valuable. Forever.": "Persönlich. Wertvoll. Für immer.",
    "Start creating": "Jetzt loslegen",
    "Generate your own Artwork or upload a picture": "Erstelle Dein eigenes Kunstwerk oder lade ein Bild hoch",
    "Generate artwork": "Kunstwerk gestalten",
    "Upload picture": "Bild hochladen",
    "Upload your Picture": "Lade Dein Bild hoch",
    "Click here to choose your picture": "Hier klicken, um Bild auszuwählen",
    "Choose your picture": "Wähle Dein Bild aus",
    "successfully added": "erfolgreich hinzugefügt",
    "What title would you like to give your NFT?": "Welchen Titel möchtest Du Deinem NFT geben?",
    "NFT title": "NFT Titel",
    "This field is mandatory": "Dies ist ein Pflichtfeld",
    "Your NFT title will also be stored online in the Interplanetary File System (IPFS)": "Dein NFT Titel wird auch im Interplanetary File System (IPFS) gespeichert",
    "Please fill in the fields marked in red.": "Bitte befülle die rot markierten Felder.",
    "Use this image": "Bild verwenden",
    "Charge your wrappr": "Lade Deinen wrappr auf",
    "What ETH value would you like to assign to your wrappr?": "Mit welchem Betrag willst Du Deinen wrapper aufladen?",
    "Value to charge": "Aufladebetrag",
    "Go to summary": "Zur Übersicht",
    "estimatedOrderValuePhrase": "wrappr charged with {{amount}} ETH ~ ${{estimatedPurchasePriceUSD}} geschätzter Bestellwert",
    "Summary and address": "Übersicht und Adresse",
    "summaryBoxValuePhrase": "wrappr aufgeladen mit einem Wert von {{amount}} ETH",
    "summaryBoxEstimatedOrderValue": "~ ${{totalValue}} geschätzter Bestellwert",
    "paymentButtonText": "{{totalValue}} $ bezahlen",
    "Voucher code": "Gutschein Code",
    "Your wrappr": "Dein wrappr",
    "Payment": "Zahlung",
    "Pay with credit card": "Mit Kreditkarte bezahlen",
    "Redeem your NFT voucher": "Löse Deinen NFT Gutschein ein",
    "Create NFT gift": "NFT Geschenk machen",
    "Redeem voucher": "Gutschein einlösen",
    "Redeem code": "Code einlösen",
    "Connect wallet": "Wallet verbinden",
    "Disconnect": "Verbindung trennen",
    "Display mode": "Anzeige",
    "dark": "dunkel",
    "light": "hell",
    "Language": "Sprache",
    "unknown": "unbekannt",
    status: {
      10: "Bestellung angelegt",
      20: "Bezahlung gestartet",
      30: "Bezahlung wird überprüft",
      40: "Bezahlung freigegeben & Blockchain Transaktion gestartet",
      50: "Blockchain Transaktion erfolgreich",
      60: "wrappr Bild wird hochgeladen",
      70: "wrappr Bild erfolgreich hochgeladen",
      80: "wrappr NFT wird geprägt",
      90: "wrappr NFT erfolgreich geprägt",
      100: "wrappr NFT wird aufgeladen",
      110: "wrappr NFT erfolgreich aufgeladen",
      120: "Gutschein Code wird hinterlegt",
      130: "Gutschein Code bereit zum Einlösen",
      140: "Gutschein Code wird eingelöst",
      150: "Gutschein erfolgreich eingelöst",
    },
    nftCreationProcedure: {
      question: "An Wallet senden oder Gutschein Code erstellen?",
      options: {
        sendToWallet: {
          title: "An Wallet senden",
          text: "Ich kenne die Crypto-Adresse des Empfängers, dem ich den wrappr senden möchte.",
        },
        createVoucher: {
          title: "Gutschein-Code erstellen",
          text: "Ich kenne die Crypto-Adresse des Empfängers nicht oder er hat noch kein eigenes Wallet. " +
            "Stattdessen möchte ich eine Gutschein-Code erzeugen, der später eingelöst werden kann.",
        }
      }
    },
    email: {
      label: "E-Mail Adresse",
      helperText: "wrappr sendet nur eine Bestellbestätigung an diese E-Mail Adresse. Wir erstellen keinen Account für " +
        "Dich und Du kannst Dich später registrieren, falls gewünscht.",
    },
    cardNumber: {
      label: "Kreditkartennummer",

    },
    givenName: {
      label: "Vorname"
    },
    familyName: {
      label: "Nachname"
    },
    creditCardExpiryDate: {
      label: "Gültig bis"
    },
    cvc: {
      label: "CVC Code"
    },
    voucherRedeemTargetAddress: {
      label: "Crypto Adresse oder .eth Domain",
      question: "An welche Adresse soll gesendet werden?"
    },
    voucherCodeRaw: {
      label: "Gutscheincode",
      question: "Bitte gebe den Gutscheincode ein"
    }
  },
};
