import * as React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { ReactChild, ReactChildren, ReactFragment } from "react";
import Footer from "../Footer/Footer";

interface LayoutProps {
  children: ReactChild | ReactChildren | ReactFragment;
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <ResponsiveAppBar />
      {children}
      <Footer/>
    </>
  );
}
