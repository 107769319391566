/*
 * Copyright © 2022. wrappr.
 */
export const API_HOST = <string>process.env.REACT_APP_API_HOST;
export const API_PATH = "";
export const ETHERSCAN_BASE_URL =<string>
  process.env.REACT_APP_DEPLOYMENT === "production"
    ? "https://etherscan.io"
    : "https://" + process.env.NETWORK + ".etherscan.io";
export const MERCURE_PREFIX = <string>process.env.REACT_APP_MERCURE_PREFIX;
export const MERCURE_URL = <string>process.env.REACT_APP_MERCURE_URL;
