import * as React from "react";
import { Avatar, Card, CardHeader, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface CardProps {
  active: boolean | null;
  icon: ReactNode;
  title: string;
  text: string;
}

export function SelectionCard(props: CardProps) {
  const theme = useTheme();
  let borderColor = props.active ? theme.palette.primary.main : "";
  return (
    <Card variant={"outlined"} sx={{ width: "100%", borderColor: borderColor }}>
      <CardHeader title={props.title} subheader={props.text} avatar={<Avatar>{props.icon}</Avatar>} />
    </Card>
  );
}
