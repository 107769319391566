/*
 * Copyright © 2022. wrappr.
 */

import { REQUEST_LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "./actionTypes";
import { loadCurrentUser, loadToken } from "../../services/localStorage";
import { AppUserTypes } from "../../types/AppUserTypes";

let localStorageAppUser: AppUserTypes = loadCurrentUser();

let token = loadToken();

interface Action {
  type: string;
  payload:  AppUserTypes;
  error?: string;
}

export interface AuthReducerState {
  appUser: AppUserTypes|null;
  token: string|undefined;
  loading: boolean;
  errorMessage?: string|null;
}

export const initialState: AuthReducerState = {
  appUser: {id: null, "@id": null, email: null} || localStorageAppUser,
  token: "" || token,
  loading: false,
  errorMessage: null
};

export function AuthReducer(initialState: AuthReducerState, action: Action):AuthReducerState {
  switch (action.type) {

    case REQUEST_LOGIN:
      return {
        ...initialState,
        loading: true
      };

    case LOGIN_SUCCESS:
      return {
        ...initialState,
        appUser: action.payload,
        token: action.payload.token,
        loading: false
      };

    case LOGOUT:
      return {
        ...initialState,
        appUser: null,
        token: ""
      };

    case LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
