/*
 * Copyright © 2022. wrappr.
 */

import React, { ChangeEvent, useState } from "react";
import { useForm } from "../../../context/Form/FormProvider";
import { Grid } from "@mui/material";
import { DropzoneAreaBase, FileObject } from "react-mui-dropzone";
import FormSubmit from "../../FormComponents/FormSubmit/FormSubmit";
import ValidatorTextField from "../../FormComponents/ValidatorTextField/ValidatorTextField";
import { isRequired } from "../../../services/validationRules";
import { useTranslation } from "react-i18next";
import { FileUploadDataTypes } from "../../../types/FileUploadDataTypes";

export function UploadForm() {
  const { handleSubmit, handleChange } = useForm();
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileObject[]>([]);


  const handleAdd = (newFiles: Array<any>) => {
    setFiles([...newFiles]);
    let fileUploadData: FileUploadDataTypes = {
      fileData: newFiles[0].data,
      originalName: newFiles[0].file.name,
      type: newFiles[0].file.type,
      size: newFiles[0].file.size
    };
    let fileEvent: ChangeEvent = {
      target: {
        // @ts-ignore
        name: "fileUploadData",
        value: fileUploadData
      }
    };
    handleChange(fileEvent);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <DropzoneAreaBase
            fileObjects={files}
            filesLimit={1}
            acceptedFiles={["image/*"]}
            dropzoneText={t("Choose your picture")}
            onAdd={handleAdd}
            showPreviewsInDropzone={true}
            previewGridClasses={{
              container: "cc",
              item: "",
              image: ""
            }}
            getFileAddedMessage={(fileName) => {return fileName + " " + t("successfully added")}}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            id={"nftTitle"}
            name={"nftTitle"}
            question={t("What title would you like to give your NFT?")}
            label={t("NFT title")}
            validators={[{validator: isRequired, message: "This field is mandatory"}]}
            helperText={t("Your NFT title will also be stored online in the Interplanetary File System (IPFS)")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSubmit hideBackButton nextText={t("Use this image")} />
        </Grid>
      </Grid>
    </form>
  );
}
