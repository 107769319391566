import { createTheme } from "@mui/material/styles";
import PoppinsWoff from "./fonts/poppins-v19-latin-regular.woff";
import PoppinsEot from "./fonts/poppins-v19-latin-regular.eot";
import PoppinsTff from "./fonts/poppins-v19-latin-regular.ttf";
import PoppinsSvg from "./fonts/poppins-v19-latin-regular.svg";
import PoppinsWoff2 from "./fonts/poppins-v19-latin-regular.woff2";
import Poppins600Woff from "./fonts/poppins-v19-latin-600.woff";
import Poppins600Eot from "./fonts/poppins-v19-latin-600.eot";
import Poppins600Tff from "./fonts/poppins-v19-latin-600.ttf";
import Poppins600Svg from "./fonts/poppins-v19-latin-600.svg";
import Poppins600Woff2 from "./fonts/poppins-v19-latin-600.woff2";
import { blue } from "@mui/material/colors";
import _merge from "lodash/merge";

let basicTheme = {
  typography: {
    fontFamily: "Poppins, Roboto, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        /* poppins-regular - latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          src: url(${PoppinsEot}); /* IE9 Compat Modes */
          src: local(''),
               url(${PoppinsEot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
               url(${PoppinsWoff2}) format('woff2'), /* Super Modern Browsers */
               url(${PoppinsWoff}) format('woff'), /* Modern Browsers */
               url(${PoppinsTff}) format('truetype'), /* Safari, Android, iOS */
               url(${PoppinsSvg}#Poppins) format('svg'); /* Legacy iOS */
        }
        
        /* poppins-600 - latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          src: url(${Poppins600Eot}); /* IE9 Compat Modes */
          src: local(''),
               url(${Poppins600Eot}?#iefix) format('embedded-opentype'), /* IE6-IE8 */
               url(${Poppins600Woff2}) format('woff2'), /* Super Modern Browsers */
               url(${Poppins600Woff}) format('woff'), /* Modern Browsers */
               url(${Poppins600Tff}) format('truetype'), /* Safari, Android, iOS */
               url(${Poppins600Svg}#Poppins) format('svg'); /* Legacy iOS */
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: "0px important!",
        },
        h1: {
          fontSize: "1.75rem",
        },
        h2: {
          fontSize: "1.5rem",
        },
        h3: {
          fontSize: "1.25rem",
        },
        body2: {
          fontSize: "1rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        imageContainer: {
          flexGrow: "1 !important",
          maxWidth: "100% !important",
        },
        removeButton: {
          display: "none !important",
        },
      },
    },
  },
};

let lightOverrides = {
  palette: {
    mode: "light",
    primary: {
      ...blue,
      main: blue[700],
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h2: {
          color: "#333",
        },
        body2: {
          color: "#333",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
            WebkitTextFillColor: '#000'
          },
        },
      }
    },
  },
};

let darkOverrides = {
  palette: {
    mode: "dark",
    primary: {
      ...blue,
      main: blue[700],
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h2: {
          color: "#C5C5C5",
        },
        body2: {
          color: "#C5C5C5",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #000 inset',
            WebkitTextFillColor: '#fff'
          },
        },
      }
    },
  },
};

const lightTheme = createTheme({
  ..._merge(basicTheme, lightOverrides),
});

const darkTheme = createTheme({
  ..._merge(basicTheme, darkOverrides),
});

export { lightTheme, darkTheme };
