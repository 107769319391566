/*
 * Copyright © 2022. wrappr.
 */
import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import FooterLinkItem from "./FooterLinkItem";

export default function Footer() {

  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="xl" sx={{ pt: 20, pb: 5 }}>
        <FooterLinkItem text={t("Imprint")} to={"/imprint"} />
      </Container>
    </>
  );
}
