import React from "react";
import { useForm } from "../../../context/Form/FormProvider";
import { Grid } from "@mui/material";
import ValidatorTextField from "../../FormComponents/ValidatorTextField/ValidatorTextField";
import FormSubmit from "../../FormComponents/FormSubmit/FormSubmit";

const RedeemForm = () => {
  const { handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <ValidatorTextField
            id={"voucherRedeemTargetAddress"}
            name={"voucherRedeemTargetAddress"}
            question={"voucherRedeemTargetAddress.question"}
            label={"voucherRedeemTargetAddress.label"}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            id={"voucherCodeRaw"}
            name={"voucherCodeRaw"}
            question={"voucherCodeRaw.question"}
            label={"voucherCodeRaw.label"}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSubmit hideBackButton nextText={"Redeem code"} />
        </Grid>
      </Grid>
    </form>
  );
};

export default RedeemForm;
