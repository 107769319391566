import _has from "lodash/has";
import _get from "lodash/get";
import { ValidatorTypes } from "../../types/ValidatorTypes";

const callValidator = async (validator: ValidatorTypes, fieldName: string, values: Object) => {
  const value = _get(values, fieldName);
  const relatedField = _get(validator, "relatedField");

  if (!!relatedField) {
    if (relatedField === "allValues") {
      return await validator.validator(value, values);
    }
    if (!_has(values, relatedField)) {
      //so that the error is not shown on onblur
      return true;
    }
    return await validator.validator(value, _get(values, relatedField));
  }

  if (_has(validator, "params")) {
    return await validator.validator(value, ...validator.params);
  }
  return await validator.validator(value);
};

async function fieldValidate (fieldName: string, fieldValidators: Array<ValidatorTypes>, values: Object): Promise<string|null> {
  for (let i = 0; i < fieldValidators.length; i++) {
    const isValid = await callValidator(fieldValidators[i], fieldName, values);
    if (!isValid) {
      return fieldValidators[i].message;
    }
  }
  return null;
}

export const formValidate = async (values:any, validators:any) => {
  let errors:any = {};
  for (let fieldName in validators) {
    if (_has(validators, fieldName) && _has(values, fieldName)) {
      errors[fieldName] = await fieldValidate(fieldName, validators[fieldName], values);
    }
  }
  return errors;
};
