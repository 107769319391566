/*
 * Copyright © 2022. wrappr.
 */

import React, { useEffect, useState } from "react";
import _random from "lodash/random";

// Apes
import Ape0 from "../../assets/Apes/unnamed (0).png";
import Ape1 from "../../assets/Apes/unnamed (1).png";
import Ape2 from "../../assets/Apes/unnamed (2).png";
import Ape3 from "../../assets/Apes/unnamed (3).png";
import Ape4 from "../../assets/Apes/unnamed (4).png";
import Ape5 from "../../assets/Apes/unnamed (5).png";
import Ape6 from "../../assets/Apes/unnamed (6).png";
import Ape7 from "../../assets/Apes/unnamed (7).png";
import Ape8 from "../../assets/Apes/unnamed (8).png";
import Ape9 from "../../assets/Apes/unnamed (9).png";
import Ape10 from "../../assets/Apes/unnamed (10).png";
import Ape11 from "../../assets/Apes/unnamed (11).png";
import Ape12 from "../../assets/Apes/unnamed (12).png";
import Ape13 from "../../assets/Apes/unnamed (13).png";
import Ape14 from "../../assets/Apes/unnamed (14).png";
import Ape15 from "../../assets/Apes/unnamed (15).png";
import Ape16 from "../../assets/Apes/unnamed (16).png";
import Ape17 from "../../assets/Apes/unnamed (17).png";
import Ape18 from "../../assets/Apes/unnamed (18).png";
import Ape19 from "../../assets/Apes/unnamed (19).png";
import Ape20 from "../../assets/Apes/unnamed (20).png";
import Ape21 from "../../assets/Apes/unnamed (21).png";
import Ape22 from "../../assets/Apes/unnamed (22).png";
import Ape23 from "../../assets/Apes/unnamed (23).png";
import Ape24 from "../../assets/Apes/unnamed (24).png";
import Ape25 from "../../assets/Apes/unnamed (25).png";
import Ape26 from "../../assets/Apes/unnamed (26).png";

import { Box } from "@mui/material";

export function ImageSwitcher() {
  const [ape, setApe] = useState(Ape0);
  let apes = [
    Ape0,
    Ape1,
    Ape2,
    Ape3,
    Ape4,
    Ape5,
    Ape6,
    Ape7,
    Ape8,
    Ape9,
    Ape10,
    Ape11,
    Ape12,
    Ape13,
    Ape14,
    Ape15,
    Ape16,
    Ape17,
    Ape18,
    Ape19,
    Ape20,
    Ape21,
    Ape22,
    Ape23,
    Ape24,
    Ape25,
    Ape26,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setApe(apes[_random(0, 26)]);
    }, 700);
    return () => clearInterval(interval);
  }, []);

  return <Box component={"img"} src={ape} />;
}
