import { useCallback, useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

// Enter a valid infura key here to avoid being rate limited
// You can get a key for free at https://infura.io/register
const INFURA_ID = "INVALID_INFURA_KEY";
const NETWORK_NAME = "mainnet";
const AUTO_LOAD = false;

interface ModalConfig {
  autoLoad: boolean;
  infuraId: string;
  network: string;
}

function useWeb3Modal(config: ModalConfig = { autoLoad: AUTO_LOAD, infuraId: INFURA_ID, network: NETWORK_NAME }) {
  const [provider, setProvider] = useState<Web3Provider | null>(null);
  const [autoLoaded, setAutoLoaded] = useState(false);
  const [signedInAddress, setSignedInAddress] = useState(null);
  const [walletConnected, setWalletConnected] = useState(false);

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: "INFURA_ID", // required
      },
    },
  };

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal
  const web3Modal = new Web3Modal({
    network: config.network,
    cacheProvider: true,
    providerOptions: providerOptions,
  });

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();
    setProvider(new Web3Provider(newProvider));
    setSignedInAddress(newProvider.selectedAddress);
    setWalletConnected(true);
  }, [web3Modal]);

  const logoutOfWeb3Modal = useCallback(
    async function () {
      setSignedInAddress(null);
      setWalletConnected(false);
      await web3Modal.clearCachedProvider();
      window.location.reload();
    },
    [web3Modal]
  );

  // If autoLoad is enabled and the wallet had been loaded before, load it automatically now.
  useEffect(() => {
    if (config.autoLoad && !autoLoaded && web3Modal.cachedProvider) {
      loadWeb3Modal();
      setAutoLoaded(true);
    }
  }, [config.autoLoad, autoLoaded, loadWeb3Modal, setAutoLoaded, web3Modal.cachedProvider]);

  return [provider, loadWeb3Modal, logoutOfWeb3Modal, signedInAddress, walletConnected];
}

export default useWeb3Modal;
