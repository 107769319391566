import React, { useContext } from "react";
import Web3Context from "../../context/Web3/Web3Context";
import { Button } from "@mui/material";

const axios = require("axios");

export async function pinJSONToIPFS(pinataApiKey: string, pinataSecretApiKey: string) {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

  let postData = JSON.stringify({
    pinataMetadata: {
      name: "ItemStatus",
      keyvalues: {
        ItemID: "Item001",
        CheckpointID: "Checkpoint002",
        Source: "CompanyA",
        WeightInKilos: 5.25,
        Nachname: "Schmitz",
      },
    },
    pinataContent: {
      itemName: "exampleItemName",
      inspectedBy: "Inspector001",
    },
  });

  return axios
    .post(url, postData, {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        pinata_api_key: pinataApiKey,
        pinata_secret_api_key: pinataSecretApiKey,
      },
    })
    .then(function (response: any) {
      console.log(response);
    })
    .catch(function (error: any) {
      console.log(error);
    });
}

const TestPage = () => {
  const { signedInAddress } = useContext(Web3Context);

  return (
    <>
      <div>{signedInAddress}</div>
      <Button
        onClick={async () => {
          await pinJSONToIPFS(
            "163a98447a0b4022ed0a",
            "c44ae79df4330340fd35e21f09b9d626fc65440a66e3d8154fb6ed1bf66db5b2"
          );
        }}
      >
        Pin with Pinata
      </Button>
    </>
  );
};

export default TestPage;
