import * as React from "react";
import { Alert, Container, Grid, Snackbar, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider } from "../../context/Form/FormProvider";
import RedeemForm from "../../components/Forms/RedeemForm/RedeemForm";
import { hashKeccak256 } from "../../services/contract-functions";
import ApiClient from "../../services/ApiClient";
import { useState } from "react";

export function RedeemPage() {
  const { t } = useTranslation();
  const [alertText, setAlertText] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  let initialValues = {};

  async function handleSubmit ({values}: any) {
    let {voucherCodeRaw, voucherRedeemTargetAddress} = values;

    // hash rawVoucherCode - we never send a raw code to the API
    const voucherCodeHash = hashKeccak256(voucherCodeRaw);

    // find relevant order
    const wrapprOrderResponse = await ApiClient.post("redeem_voucher", {
      body: JSON.stringify({
        voucherCodeHash: voucherCodeHash,
        voucherRedeemTargetAddress: voucherRedeemTargetAddress
      })
    });
    const wrapprOrders = wrapprOrderResponse["hydra:member"];
    if (wrapprOrders.length === 0) {
      setAlertText("Your voucher code could not be found");
      setSnackbarOpen(true);
      return;
    }
  }

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Snackbar open={snackbarOpen} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {alertText}
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Redeem your NFT voucher")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
            <RedeemForm/>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
