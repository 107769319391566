import PropTypes from "prop-types";
import React, { FocusEventHandler, ReactNode, useEffect } from "react";
import { FormControl, FormLabel, InputAdornment, TextField, Typography } from "@mui/material";
import { formValue } from "../../../services/formServiceFunctions";
import _get from "lodash/get";
import { useForm } from "../../../context/Form/FormProvider";
import { ValidatorTypes } from "../../../types/ValidatorTypes";
import { useTranslation } from "react-i18next";

interface ValidatorTextFieldTypes {
  id: string;
  name: string;
  label: string;
  question?: string;
  startAdornmentIcon?: ReactNode;
  onFocus?: FocusEventHandler;
  helperText?: string;
  validators?: Array<ValidatorTypes>;
  dependentValidationFields?: Array<any>;
}

export default function ValidatorTextField({
                                             id,
                                             name,
                                             label,
                                             question,
                                             startAdornmentIcon,
                                             onFocus,
                                             helperText,
                                             validators,
                                             dependentValidationFields,
                                             ...rest
                                           }: ValidatorTextFieldTypes)
{
  const { values, errors, setError, handleChange, handleBlur, registerValidators } = useForm();
  const { t } = useTranslation();
  const error = !!_get(errors, name);
  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, []);

  return (
    <FormControl sx={{ width: "100%" }}>
      {question && (
        <FormLabel id={id + "-label"} sx={{ mb: 4 }}>
          <Typography variant={"h2"} textAlign={"center"}>
            {t(question)}
          </Typography>
        </FormLabel>
      )}
      <TextField
        id={id}
        name={name}
        label={t(label)}
        value={formValue(values, name)}
        error={error}
        helperText={t(_get(errors, name) || helperText)}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {startAdornmentIcon}
            </InputAdornment>
          )
        }}
        {...rest}
      />
    </FormControl>
  );
}
ValidatorTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dependentValidationFields: PropTypes.array.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.oneOf(["normal", "dense"])
};
ValidatorTextField.defaultProps = {
  fullWidth: true,
  margin: "normal",
  dependentValidationFields: [],
  validators: []
};
