/*
 * Copyright © 2022. wrappr.
 */

import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import ApiClient from "../../services/ApiClient";
import { WrapprOrderTypes } from "../../types/WrapprOrderTypes";
import { ImageSwitcher } from "../../components/ImageSwitcher/ImageSwitcher";
import { ETHERSCAN_BASE_URL } from "../../config/globalVars";
import OrderNotifications from "../../components/OrderNotifications/OrderNotifications";
import { STATUS_VOUCHER_HASH_ADDED } from "../../services/orderStatus";

export function ThankYouPage() {
  const [isProcessing, setIsProcessing] = useState(true);
  const [, setError] = useState("");
  const [order, setOrder] = useState<WrapprOrderTypes | null>(null);
  const [hasUpdates, setHasUpdates] = useState(false);

  async function fetchOrder(id: string | null) {
    let fetchedOrder = null;
    try {
      fetchedOrder = await ApiClient.get("wrappr_orders/" + id);
      setOrder(fetchedOrder);
      if (parseInt(fetchedOrder.status, 10) == STATUS_VOUCHER_HASH_ADDED) {
        setIsProcessing(false);
      }
    } catch (error: any) {
      setError(error.message);
    }
    setHasUpdates(false);
    return fetchedOrder;
  }

  useEffect(() => {
    const wrapprOrderId = localStorage.getItem("wrapprOrderId");
    fetchOrder(wrapprOrderId).then(() => {
    });
  }, [hasUpdates]);

  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth={"xs"}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"h1"} textAlign={"center"}>
              Thank you!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h2"} textAlign={"center"}>
              Your wrappr is being minted and will be sent to your wallet in just a minute...
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <>{isProcessing && <ImageSwitcher />}
              {!isProcessing && order && order.wrapprNft.fileData &&
                <Box component={"img"} src={order.wrapprNft.fileData} sx={{ maxWidth: "100%", borderRadius: 5 }} />
              }
            </>
          </Grid>
          <>
          {(order && order.status === "30" && order.wyreOrder) &&
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button variant={"contained"} component={Link} href={order.wyreOrder.authorization3dsUrl} target={"_blank"}>
                  Authorize payment
                </Button>
              </Grid>
          }
          </>
          <>
            {(order && order.wyreOrder.blockchainNetworkTx) && (
              <Grid item xs={12}>
                You can check your transaction on
                <a
                  href={ETHERSCAN_BASE_URL + "/tx/" + order.wyreOrder.blockchainNetworkTx}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {" "}
                  Etherscan
                </a>
              </Grid>
            )}
            {order && order.wrapprNft.ipfsMetadataHash && order.wrapprNft.ipfsHash && (
              <Grid item xs={12}>
                <p>Your NFT was minted and stored to the Interplanetary File System (IPFS):</p>
                <a
                  href={"https://ipfs.io/ipfs/" + order.wrapprNft.ipfsMetadataHash}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Metadata
                </a>
                {" and "}
                <a href={"https://ipfs.io/ipfs/" + order.wrapprNft.ipfsHash} target="_blank"
                   rel="noopener noreferrer nofollow">
                  Picture
                </a>
              </Grid>
            )}
            {order &&
              <Grid item xs={12}>
                Order ID: {order.id}
              </Grid>
            }
          </>
        </Grid>
      </Container>
      <OrderNotifications order={order} setHasUpdates={setHasUpdates} />
    </>
  );
}
