import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import stampTeaser from "../../assets/4StampsTeaser.png";
import { Link } from "react-router-dom";

export function HomePage() {
  const { t } = useTranslation();
  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Give a wrappr")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={"h2"} textAlign={"center"}>
            {t("Personal. Valuable. Forever.")}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant={"contained"} size={"large"} component={Link} to={"/choose-picture"}>
            {t("Start creating")}
          </Button>
        </Grid>
        <Grid item sx={{ textAlign: "center" }}>
          <Box component="img" src={stampTeaser} sx={{ maxWidth: "90%", margin: "auto" }} />
        </Grid>
      </Grid>
    </Container>
  );
}
