import * as React from "react";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { OrderSummaryBox } from "../../components/OrderSummaryBox/OrderSummaryBox";
import { FormProvider } from "../../context/Form/FormProvider";
import CryptoAddressVoucherForm from "../../components/Forms/CryptoAddressVoucherForm/CryptoAddressVoucherForm";
import { FormValueTypes } from "../../types/FormValueTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function SummaryPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let initialValues: FormValueTypes = JSON.parse(String(localStorage.getItem("values")));

  const handleSubmit = async ({ values }: any) => {
    localStorage.setItem("values", JSON.stringify(values));
    navigate("/payment");
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Summary and address")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h2"} textAlign={"center"}>
            {t("Your wrappr")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OrderSummaryBox
            amount={initialValues.amount}
            ethToUSDConversion={initialValues.ethToUSDConversion}
            fileData={initialValues.fileUploadData?.fileData}
            nftTitle={initialValues.nftTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 0 }} />
        </Grid>
        <Grid item xs={12}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
            <CryptoAddressVoucherForm />
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
