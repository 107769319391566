import React, { useState } from "react";
import _round from "lodash/round";
import { useForm } from "../../../context/Form/FormProvider";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import FormSubmit from "../../FormComponents/FormSubmit/FormSubmit";
import { FormPropTypes } from "../../../types/FormPropTypes";
import ValidatorTextField from "../../FormComponents/ValidatorTextField/ValidatorTextField";
import ReactCreditCard from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import MaskedValidatorTextField from "../../FormComponents/ValidatorTextField/MaskedValidatorTextField";
import { isRequired } from "../../../services/validationRules";
import { useTranslation } from "react-i18next";

const PaymentForm = ({ isLoading }: FormPropTypes) => {
  const { handleSubmit, values } = useForm();
  const [focused, setFocused] = useState<string>("");
  const { t } = useTranslation();

  const handleInputFocus = (e: React.FocusEvent<Element>) => {
    const target = e.target as HTMLInputElement;
    if (target && target.name) {
      setFocused(target.name);
    }
  };

  const buttonText = t("paymentButtonText", "-", { totalValue: _round(values.amount * values.ethToUSDConversion, 2) });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ValidatorTextField
            id={"email"}
            name={"email"}
            label={t("email.label")}
            helperText={t("email.helperText")}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2, mt: 2 }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h2"} textAlign={"center"}>
            {t("Pay with credit card")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReactCreditCard
            cvc={values.cvc}
            expiry={values.creditCardExpiryDate}
            focused={focused}
            name={values.givenName + " " + values.familyName}
            number={values.cardNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <MaskedValidatorTextField
            id={"cardNumber"}
            name={"cardNumber"}
            mask={[
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
            label={"cardNumber.label"}
            onFocus={(e: React.FocusEvent<Element, Element>) => handleInputFocus(e)}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidatorTextField
            id={"givenName"}
            name={"givenName"}
            label={"givenName.label"}
            onFocus={handleInputFocus}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidatorTextField
            id={"familyName"}
            name={"familyName"}
            label={"familyName.label"}
            onFocus={handleInputFocus}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>
        <Grid item xs={6}>
          <MaskedValidatorTextField
            id={"creditCardExpiryDate"}
            name={"creditCardExpiryDate"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/]}
            label={"creditCardExpiryDate.label"}
            onFocus={handleInputFocus}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>
        <Grid item xs={6}>
          <ValidatorTextField
            id={"cvc"}
            name={"cvc"}
            label={"cvc.label"}
            onFocus={handleInputFocus}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 30 }} />
          <FormSubmit
            hideBackButton
            nextText={buttonText}
            isLoading={isLoading}
          />
          <Typography sx={{ textAlign: "center" }}>
            By clicking "{buttonText}"
            I accept<br /> <Link href={"https://www.sendwyre.com/legal/user-agreement"} target={"_blank"}>Wyre's User
            Agreement</Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default PaymentForm;
