import * as React from "react";
import { useState } from "react";
import ApiClient from "../../services/ApiClient";
import { FormValueTypes } from "../../types/FormValueTypes";
import { Container, Divider, Grid, Typography } from "@mui/material";
import { OrderSummaryBox } from "../../components/OrderSummaryBox/OrderSummaryBox";
import { FormProvider } from "../../context/Form/FormProvider";
import PaymentForm from "../../components/Forms/PaymentForm/PaymentForm";
import { AppUserTypes } from "../../types/AppUserTypes";
import { WrapprOrderTypes } from "../../types/WrapprOrderTypes";
import { PersonTypes } from "../../types/PersonTypes";
import _isEmpty from "lodash/isEmpty";
import { WrapprNftTypes } from "../../types/WrapprNftTypes";
import { MediaObjectTypes } from "../../types/MediaObjectTypes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../context/Auth";
import { register } from "../../context/Auth/actions";
import { AuthReducerState } from "../../context/Auth/AuthReducer";

export function PaymentPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAuthDispatch();
  const authState: AuthReducerState = useAuthState();
  let appUser: AppUserTypes|null = authState.appUser;

  let initialValues: FormValueTypes = JSON.parse(String(localStorage.getItem("values")));

  const uploadFile = async (fileUploadData: any) => {
    let res = await fetch(fileUploadData.fileData);
    let blob = await res.blob();
    let file = new File([blob], fileUploadData.originalName);

    let formData = new FormData();
    formData.append("file", file);
    let headers = new Headers();
    headers.set("Content-Type", "multipart/form-data");
    const mediaObject: MediaObjectTypes = await ApiClient.post("media_objects", {
      headers: headers,
      body: formData
    });
    return mediaObject;
  };

  const handleSubmit = async ({ values, errors }: any) => {
    setIsLoading(true);
    localStorage.setItem("values", JSON.stringify(values));
    if (!_isEmpty(errors)) {
      return;
    }

    const data = {
      email: values.email
    }

    try {
      appUser = await register(dispatch, data);
    } catch (e) {
      console.log(e);
    }

    if (!appUser || !appUser.id) {
      console.log('AppUser without id.');
      return;
    }

    const mediaObject = await uploadFile(values.fileUploadData);
    const person: PersonTypes = await ApiClient.post("people", {
      body: JSON.stringify({
        givenName: values.givenName,
        familyName: values.familyName,
        email: values.email
      })
    });

    await ApiClient.put("app_users/" + appUser.id, {
      body: JSON.stringify({
        email: values.email,
        person: person["@id"]
       })
     });

    const wrapprNft: WrapprNftTypes = await ApiClient.post("wrappr_nfts", {
      body: JSON.stringify({
        nftTitle: values.nftTitle,
        fileData: values.fileUploadData.fileData,
        image: mediaObject["@id"]
      })
    });

    const wrapprOrder: WrapprOrderTypes = await ApiClient.post("wrappr_orders", {
      body: JSON.stringify({
        amount: parseFloat(values.amount),
        creditCardNumber: values.cardNumber.replace(/\s/g, ""),
        lastFourDigits: parseInt(values.cardNumber.slice(-4), 10),
        creditCardExpiryDate: values.creditCardExpiryDate,
        creditCardCvc: parseInt(values.cvc, 10),
        appUser: appUser["@id"],
        destCurrency: "ETH",
        wrapprNft: wrapprNft["@id"],
        nftCreationProcedure: values.nftCreationProcedure,
        voucherCodeHash: values.voucherCodeHash
      })
    });
    localStorage.setItem("wrapprOrderId", JSON.stringify(wrapprOrder.id));
    setIsLoading(false);
    navigate("/thank-you");
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Payment")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <OrderSummaryBox
            amount={initialValues.amount}
            ethToUSDConversion={initialValues.ethToUSDConversion}
            fileData={initialValues.fileUploadData?.fileData}
            nftTitle={initialValues.nftTitle}
            generatedVoucherCode={initialValues.generatedVoucherCode}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 0 }} />
        </Grid>
        <Grid item xs={12}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
            <PaymentForm isLoading={isLoading} />
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
