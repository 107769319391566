import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Web3Context from "../../context/Web3/Web3Context";
import ApiClient from "../../services/ApiClient";
import { WrapprNftMetaData } from "../../types/WrapprNftMetaData";
import { GalleryTile } from "../../components/GalleryTile/GalleryTile";
import { LoadingButton } from "@mui/lab";

type Wrappr = {
  id: number,
  tokenURI: string
};

export type WrapprMetaData = {
  metaData: WrapprNftMetaData,
  nftData: Wrappr
}

export function MyWrapprsPage() {
  const { t } = useTranslation();
  const { walletConnected, loadWeb3Modal, signedInAddress } = useContext(Web3Context);
  const [wrapprs, setWrapprs] = useState([]);
  const [wrapprMetaDatas, setWrapprMetaDatas]: any = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoadedWrappr, setLastLoadedWrappr] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchWrapprs().then(() => {
    });
  }, [signedInAddress]);

  useEffect(() => {
    wrapprs.forEach(getWrapprData);
  }, [wrapprs]);

  useEffect(() => {
    console.log("lastLoadedWrappr " + lastLoadedWrappr);
  }, [lastLoadedWrappr]);

  async function fetchWrapprs() {
    if (signedInAddress) {
      try {
        setIsLoading(true);
        const wrapprs = await ApiClient.post("blockchain/list_tokens_of_owner", {
          body: JSON.stringify({
            tokenOwnerAddress: signedInAddress
          })
        });
        if (wrapprs) {
          setWrapprs(wrapprs);
        }
        setIsLoading(false);
      } catch (err: any) {
        setError(err);
      }
    }
  }

  async function getWrapprData(wrappr: Wrappr) {
    const updatedWrapprMetaDatas = wrapprMetaDatas;
    if (wrappr.id !== 12 && wrappr.id !== 15) {
      updatedWrapprMetaDatas[wrappr.id] = {
        metaData: null,
        nftData: wrappr
      };
      setWrapprMetaDatas(updatedWrapprMetaDatas);
      setLastLoadedWrappr(wrappr.id);
      return;
    }
    const response = await fetch(wrappr.tokenURI);
    const wrapprNftMetaData: WrapprNftMetaData = await response.json();

    updatedWrapprMetaDatas[wrappr.id] = {
      metaData: wrapprNftMetaData,
      nftData: wrappr
    };
    setWrapprMetaDatas(updatedWrapprMetaDatas);
    setLastLoadedWrappr(wrappr.id);
  }

  if (error) {
    return <>Error...</>;
  }

  console.log(wrapprMetaDatas);

  function renderGalleryTiles(wrapprMetaDatas: any) {
    const wrapprMetaDatasArray = Object.values(wrapprMetaDatas);
    return (
      <>
        {wrapprMetaDatasArray.map((wrapprMetaData: any) =>
          <GalleryTile key={wrapprMetaData.nftData.id} wrapprMetaData={wrapprMetaData} />
        )}
      </>
    );
  }

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container direction={"column"} spacing={4} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("My wrapprs")}
          </Typography>
        </Grid>
        {(!walletConnected || isLoading) &&
          <>
            <Grid item xs={12}>
              <Typography textAlign={"center"}>
                {t("To see your wrapprs, please connect your wallet")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <LoadingButton
                variant={"contained"}
                onClick={loadWeb3Modal}
                loading={isLoading}
              >
                {t("Connect wallet")}
              </LoadingButton>
            </Grid>
          </>
        }

        {wrapprs &&
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Grid container spacing={4} direction={"column"} alignItems={"center"}>
              {renderGalleryTiles(wrapprMetaDatas)}
            </Grid>
          </Grid>
        }
      </Grid>
    </Container>
  );
}
