/*
 * Copyright © 2022. wrappr.
 */

import React, { ReactChild, ReactFragment, ReactPortal } from "react";
import Web3Context from "./Web3Context";
import useWeb3Modal from "./useWeb3Modal";


const Web3Provider = (props: { children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined }) => {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal, signedInAddress, walletConnected] = useWeb3Modal();

  return (<Web3Context.Provider value={{ provider, loadWeb3Modal, logoutOfWeb3Modal, signedInAddress, walletConnected }}>{props.children}</Web3Context.Provider>);
}

export default Web3Provider;
