import Immutable from "seamless-immutable";
import jwtDecode from "jwt-decode";
import _ from "lodash";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return Immutable(JSON.parse(serializedState));
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore errors for now
  }
};

export const persistToken = (token: string) => {
  localStorage.setItem("wrapprJWT", token);
};

export const loadToken = () => {
  const token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser") ?? "").token
    : null;
  if (!token) {
    return null;
  }
  const decodedToken: any = jwtDecode(token);
  if (decodedToken.exp < Date.now() / 1000) {
    clearCurrentUser();
    return null;
  }
  return token;
};

export const loadCurrentUser = () => {
  return localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser") ?? "").user
    : null;
}

export const persistCurrentUser = (appUser:{}) => {
  localStorage.setItem('currentUser', JSON.stringify(appUser));
}

export const clearCurrentUser = () => {
  localStorage.removeItem('currentUser');
}

export const isValidJwtToken = (token: string | any[], userState: any = null) => {
  if (!_.isString(token) || token.length === 0 || !userState) {
    return false;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    return (
      (decodedToken.username === userState.email || decodedToken.roles.includes("ROLE_ADMIN")) &&
      decodedToken.exp > Date.now() / 1000
    );
  } catch (err) {
    return false;
  }
};
