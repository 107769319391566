/*
 * Copyright © 2022. wrappr.
 */
import React, { useContext } from "react";
import ImprintPageEN from "./ImprintPageEN";
import ImprintPageDE from "./ImprintPageDE";
import LanguageContext from "../../context/Language/LanguageContext";

export function ImprintPage() {
  const { language } = useContext(LanguageContext);

  if  (language === "de") {
    return <ImprintPageDE/>
  } else {
    return <ImprintPageEN/>
  }
}
