/** @jsxImportSource @emotion/react */
import * as React from "react";
import Box from "@mui/material/Box";
import * as PropTypes from "prop-types";


let connectionIndicatorStyles = {
    base: {
        bottom: 0,
        right: 0,
        width: 8,
        height: 8,
        borderRadius: 5,
        position: "absolute",
        background: "#333333"
    },
    active: {
        background: "#00d897"
    }
};

const ConnectionIndicator = (props: {active: any}) => {
    return <Box sx={{...connectionIndicatorStyles.base, ...(props.active && connectionIndicatorStyles.active)}} />;
};

export default ConnectionIndicator;

ConnectionIndicator.propTypes = {
  active: PropTypes.bool
};
