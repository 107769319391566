import React, { MouseEventHandler, useMemo } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import { useForm } from "../../../context/Form/FormProvider";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface FormSubmitTypes {
  hideBackButton?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  nextText?: string;
  nextButtonSubtext?: string | null;
  backText?: string;
  backLink?: string;
  onBackClick?: MouseEventHandler;
}

export default function FormSubmit({
                                     hideBackButton,
                                     disabled,
                                     isLoading,
                                     nextText,
                                     nextButtonSubtext,
                                     backText,
                                     backLink,
                                     onBackClick
                                   }: FormSubmitTypes) {
  const { errors } = useForm();
  const { t } = useTranslation();
  const isValid = useMemo(() => {
    return _isEmpty(errors);
  }, [errors]);
  return (
    <Grid container justifyContent={"center"} alignItems={"baseline"} spacing={2}>
      <>
        {!isValid && (
          <Grid item xs={12}>
            <Grid container justifyContent={"center"} textAlign={"center"}>
              <Grid item xs={12}>
                {t("Please fill in the fields marked in red.")}
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <>
          {nextText &&
            <LoadingButton variant={"contained"} size={"large"} type={"submit"} disabled={disabled} loading={isLoading}>
              {t(nextText)}
            </LoadingButton>
          }
        </>
        <>
          {!!nextButtonSubtext &&
            <div>
              {t(nextButtonSubtext)}
            </div>
          }
        </>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <>
          {!hideBackButton && backText && backLink && (
            <Button variant={"text"} component={Link} onClick={onBackClick} to={backLink}>
              {t(backText)}
            </Button>
          )}
        </>
      </Grid>
    </Grid>
  );
}

FormSubmit.defaultProps = {
  hideBackButton: true,
  disabled: false,
  isLoading: false,
  nextText: "next",
  nextButtonSubtext: null,
  backText: "back",
  backLink: "/",
  onBackClick: undefined
};
