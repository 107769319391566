import * as React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { ValueChargeForm } from "../../components/Forms/ValueChargeForm/ValueChargeForm";
import { FormValueTypes } from "../../types/FormValueTypes";
import { FormProvider } from "../../context/Form/FormProvider";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function ValueChargePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let initialValues: FormValueTypes = JSON.parse(String(localStorage.getItem("values")));

  const handleSubmit = async ({ values, errors }: any) => {
    localStorage.setItem("values", JSON.stringify(values));
    if (_isEmpty(errors)) {
      navigate("/summary");
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth={"xs"}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={"h1"} textAlign={"center"}>
            {t("Charge your wrappr")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
            <ValueChargeForm />
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
