/*
 * Copyright © 2022. Stampr.
 */

import Menu from "@mui/material/Menu";
import { Box, ListItemIcon, ListItemText, MenuList } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { AccountBalanceWalletOutlined, Logout, Translate } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import CollectionsIcon from "@mui/icons-material/Collections";
import RedeemIcon from "@mui/icons-material/Redeem";
import * as React from "react";
import { EventHandler, MouseEventHandler } from "react";
import germany from "../../assets/flags/germany.png";
import unitedKingdom from "../../assets/flags/united-kingdom.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface NavMenuProps {
  anchorElNav: HTMLElement | null,
  handleCloseNavMenu: EventHandler<any>,
  logoutOfWeb3Modal: MouseEventHandler,
  walletConnected: boolean,
  toggleDarkMode: MouseEventHandler,
  darkMode: boolean,
  loadWeb3Modal: EventHandler<any>,
  switchLanguage: Function
  language: string
}

export function NavMenu(props: NavMenuProps) {
  const {
    anchorElNav,
    handleCloseNavMenu,
    logoutOfWeb3Modal,
    walletConnected,
    toggleDarkMode,
    darkMode,
    loadWeb3Modal,
    switchLanguage,
    language
  } = props;

  const { t } = useTranslation();
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
    >
      <MenuList sx={{ width: 320, maxWidth: "100%" }}>
        <MenuItem key={"create"} component={Link} to={"/choose-picture"}>
          <ListItemIcon>
            <PhotoFilterIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            {t("Create NFT gift")}
          </ListItemText>
        </MenuItem>

        <MenuItem key={"redeem"} component={Link} to={"/redeem"}>
          <ListItemIcon>
            <RedeemIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            {t("Redeem voucher")}
          </ListItemText>
        </MenuItem>

        <MenuItem key={"myWrapprs"} component={Link} to={"/my-wrapprs"}>
          <ListItemIcon>
            <CollectionsIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            {t("My wrapprs")}
          </ListItemText>
        </MenuItem>

        {walletConnected &&
          <MenuItem key={"disconnect"} onClick={logoutOfWeb3Modal}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t("Disconnect")}
            </ListItemText>
          </MenuItem>
        }
        {!walletConnected &&
          <MenuItem key={"walletConnect"} onClick={loadWeb3Modal}>
            <ListItemIcon>
              <AccountBalanceWalletOutlined fontSize={"small"} />
            </ListItemIcon>
            <ListItemText>
              {t("Connect wallet")}
            </ListItemText>
          </MenuItem>
        }
        <MenuItem key={"darkmode"} onClick={toggleDarkMode}>
          <ListItemIcon>
            {darkMode ? <Brightness7Icon fontSize={"small"} /> : <Brightness4Icon fontSize={"small"} />}
          </ListItemIcon>
          <ListItemText>
            {t("Display mode")}
          </ListItemText>
          <Typography variant="body2" color="text.secondary">
            {darkMode ? t("dark") : t("light")}
          </Typography>
        </MenuItem>

        <MenuItem key={"language"}>
          <ListItemIcon>
            <Translate fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>
            {t("Language")}
            <Box component={"img"} src={unitedKingdom} onClick={() => switchLanguage("en")} sx={{ ml: 2 }} />
            <Box component={"img"} src={germany} onClick={() => switchLanguage("de")} sx={{ ml: 2 }} />
          </ListItemText>
          <Typography variant="body2" color="text.secondary">
            {language}
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
