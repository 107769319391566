import * as React from "react";
import { FormEventHandler, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EthereumIcon } from "../../SvgIcon/EthereumIcon";
import _round from "lodash/round";
import FormSubmit from "../../FormComponents/FormSubmit/FormSubmit";
import ValidatorTextField from "../../FormComponents/ValidatorTextField/ValidatorTextField";
import { useForm } from "../../../context/Form/FormProvider";
import { FormValueTypes } from "../../../types/FormValueTypes";
import { isRequired } from "../../../services/validationRules";
import { useTranslation } from "react-i18next";

export function ValueChargeForm() {
  let { values, handleSubmit }: { values: FormValueTypes; handleSubmit: FormEventHandler } = useForm();
  const { t } = useTranslation();
  const [calculatedValues, setCalculatedValues] = useState({
    totalSumEth: 0,
    estimatedPurchasePriceUSD: 0
  });

  useEffect(() => {
    let totalSumEth = parseFloat(String(values.amount));
    let estimatedPurchasePriceUSD = totalSumEth * values.ethToUSDConversion;
    setCalculatedValues({ totalSumEth: totalSumEth, estimatedPurchasePriceUSD: estimatedPurchasePriceUSD });
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ValidatorTextField
            id={"amount"}
            name={"amount"}
            question={t("What ETH value would you like to assign to your wrappr?")}
            label={t("Value to charge")}
            startAdornmentIcon={<EthereumIcon />}
            validators={[{ validator: isRequired, message: t("This field is mandatory") }]}
          />
        </Grid>

        {values.nftTitle && (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant={"body1"}>{values.nftTitle}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
            <Grid item xs={12} sx={{textAlign: "center"}} >
              <Box component={"img"} src={values.fileUploadData?.fileData} sx={{ maxWidth: "100%", borderRadius: 5}} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          {calculatedValues.estimatedPurchasePriceUSD > 0 && (
            <Typography variant={"body2"}>
              {t("estimatedOrderValuePhrase",
                { amount: values.amount,
                  estimatedPurchasePriceUSD: _round(calculatedValues.estimatedPurchasePriceUSD, 2)
                })
              }
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormSubmit hideBackButton nextText={t("Go to summary")} />
        </Grid>
      </Grid>
    </form>
  );
}
