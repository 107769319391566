import * as React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import _round from "lodash/round";
import { useTranslation } from "react-i18next";

export function OrderSummaryBox({ ...values }) {
  const { t } = useTranslation();
  const theme = useTheme();
  let totalSumEth = parseFloat(String(values.amount));
  let estimatedPurchasePriceUSD = totalSumEth * values.ethToUSDConversion;
  let generatedVoucherCode = values.generatedVoucherCode;
  return (
    <Grid container sx={{ width: "100%" }} spacing={4} alignItems={"center"}>
      <Grid item xs={4}>
        <Box component={"img"} src={values.fileData} sx={{ maxWidth: "100%", borderRadius: 1 }} />
      </Grid>
      <Grid item xs={8}>
        <Typography variant={"h3"}>{values.nftTitle ? values.nftTitle : "Custom-made wrappr"}</Typography>
        <Typography variant={"body2"} sx={{ fontSize: "0.75rem" }}>
          {t("summaryBoxValuePhrase", {amount: values.amount})}<br />
          {t("summaryBoxEstimatedOrderValue", {totalValue: _round(estimatedPurchasePriceUSD, 2)})}<br />
          <br />
          {generatedVoucherCode ? t("Voucher code") + ":" : ""}
        </Typography>
        <Box sx={{ fontFamily: "courier", color: theme.palette.primary.main }}>{generatedVoucherCode}</Box>
      </Grid>
    </Grid>
  );
}
