/*
 * Copyright © 2022. wrappr.
 */

export const handleTestKey = (locationSearch: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
  const query = new URLSearchParams(locationSearch);
  if (!query) {
    return;
  }

  let item = query.has("testKey") ? query.get("testKey") : "";
  localStorage.setItem("testKey", item ?? "");
};

export const accessGranted = (locationSearch: string | Record<string, string> | URLSearchParams | string[][] | undefined) => {
  handleTestKey(locationSearch);
  return process.env.REACT_APP_DEPLOYMENT === "development" || localStorage.getItem("testKey") === "moon";
};
