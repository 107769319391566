/*
 * Copyright © 2022. wrappr.
 */
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { WrapprMetaData } from "../../pages/MyWrapprsPage/MyWrapprsPage";
import apeSilhouette from "../../assets/Apes/ape_silhouette.png";
import { Link } from "react-router-dom";

type GalleryTileProps = {
  wrapprMetaData: WrapprMetaData;
}
export function GalleryTile({wrapprMetaData}: GalleryTileProps) {
  const { t } = useTranslation();
  console.log(wrapprMetaData);
  const image = wrapprMetaData.metaData ? wrapprMetaData.metaData.image : apeSilhouette;
  const description = wrapprMetaData.metaData ? wrapprMetaData.metaData.description : null;
  return(
    <Grid item xs={12} sx={{ alignContent: "center" }}>
      <Paper sx={{ alignContent: "center", width: 340, padding: 2 }}>
        <Box component={"img"} src={image} sx={{borderRadius: 5, width: "100%", mb: 2}}/>
        <Typography variant={"h5"}>{wrapprMetaData.metaData ? wrapprMetaData.metaData.name: t("unknown")}</Typography>
        {description &&
          <Typography variant={"body2"}>{description}</Typography>
        }
        <Box sx={{mt: 4}}>
          <Chip label={"NFT ID " + wrapprMetaData.nftData.id} variant={"outlined"}></Chip>
          <Chip label={"NFT Metadata"} component={Link} to={wrapprMetaData.nftData.tokenURI} target={"_blank"}></Chip>
        </Box>
      </Paper>
    </Grid>
  );

}
