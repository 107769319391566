import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _set from "lodash/set";
import _pick from "lodash/pick";
import { formValidate } from "./formFunctions";
import _pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import _isArray from "lodash/isArray";
import _cloneDeep from "lodash/cloneDeep";
import FormContext from "./FormContext";

interface FormProviderProps {
  children: ReactNode;
  initialValues: Object;
  submitAction: any;
  externalErrors: any;
}

interface ProviderContextDataTypes {
  initialValues: Object;
  values: any;
  touchedValues: Object;
  errors: Object;
  clearForm: Function;
  handleBlur: FocusEventHandler;
  handleChange: ChangeEventHandler;
  handleSubmit: FormEventHandler;
  registerValidators: Function;
  setErrors: Function;
  setError: Function;
}

const FormProvider = ({ children, initialValues, submitAction, externalErrors }: FormProviderProps) => {
  const [values, setValues] = useState(_cloneDeep(initialValues));
  const [touchedValues, setTouchedValues] = useState<Object>({});
  const [errors, setErrors] = useState({});
  const validators:any = useRef({});
  const dependentValidationFields: any = useRef({});

  useEffect(() => {
    setErrors(_pickBy({ ...errors, ...externalErrors }));
  }, [externalErrors]);

  const clearForm = useCallback(() => {
    setValues(_cloneDeep(initialValues));
    setErrors({});
    setTouchedValues({});
  }, []);

  const handleChange = useCallback(({ target }: any) => {
    const { name, type } = target;
    const value = type === "checkbox" ? target.checked : target.value;
    setValues((prevData: any) => {
      return _set(_cloneDeep(prevData), name, value);
    });
    setTouchedValues((prevData) => {
      return _set(_cloneDeep(prevData), name, true);
    });
  }, []);

  const validateField = useCallback(
    (name: string | number) => {
      let fieldNamesToValidate = [name];
      if (_isArray(dependentValidationFields.current[name])) {
        fieldNamesToValidate.push(dependentValidationFields.current[name]);
      }
      const valuesToValidate = _pick(values, fieldNamesToValidate);
      formValidate(valuesToValidate, validators.current).then((currentFieldError) => {
        setErrors(_pickBy({ ...errors, ...currentFieldError }));
      });
    },
    [values, errors]
  );

  const handleBlur = useCallback(
    ({ target }: any) => {
      const { name } = target;
      setTouchedValues((prevData) => {
        return _set(_cloneDeep(prevData), name, true);
      });
      validateField(name);
    },
    [values, errors]
  );

  const handleSubmit = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      formValidate(values, validators.current).then((currentErrors) => {
        const pickedErrors = _pickBy(currentErrors);
        setErrors(pickedErrors);
        submitAction({ values, errors: pickedErrors });
      });
    },
    [submitAction, values]
  );

  const registerValidators = useCallback((name: string | number, registerValidators: any, dependentFields = []) => {
    validators.current[name] = registerValidators;
    dependentValidationFields.current[name] = dependentFields;
  }, []);

  const setError = useCallback(
    (name: any, value: any) => {
      setErrors(_pickBy({ ...errors, [name]: value }));
    },
    [errors]
  );

  const data = useMemo(
    () => ({
      initialValues: _cloneDeep(initialValues),
      values,
      touchedValues,
      errors,
      clearForm,
      handleBlur,
      handleChange,
      handleSubmit,
      registerValidators,
      setErrors,
      setError,
    }),
    [
      initialValues,
      values,
      touchedValues,
      errors,
      clearForm,
      handleBlur,
      handleChange,
      handleSubmit,
      registerValidators,
      setErrors,
      setError,
    ]
  );

  // @ts-ignore
  return <FormContext.Provider value={data}> {children} </FormContext.Provider>;
};

FormProvider.propTypes = {
  initialValues: PropTypes.object.isRequired,
  externalErrors: PropTypes.object,
  submitAction: PropTypes.func,
};

FormProvider.defaultProps = {
  externalErrors: {}
};

const useForm = (): ProviderContextDataTypes => {
  const formContext: ProviderContextDataTypes | undefined = useContext(FormContext);
  if (formContext === undefined) {
    throw new Error("useForm can only be used inside FormProvider");
  }
  return formContext;
};

export { FormProvider, useForm };
